import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contacto" />
    <section className="section hero is-fullheight section-contacto">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="title has-text-centered has-text-primary is-size-1">
              CONTACTO
            </h1>
            <div className="content is-centered has-text-centered is-size-4">
              <div>
                <p className="has-text-primary">Ventas</p>
                <a
                  className="button is-large is-primary"
                  href="tel:+50254712180"
                >
                  <span class="icon">
                    <i class="fas fa-mobile-alt"></i>
                  </span>
                  <span>+502 54712180</span>
                </a>
              </div>
              <div>
                <p className="has-text-primary">Soporte técnico</p>
                <a
                  className="button is-large is-primary"
                  href="tel:+50247069770"
                >
                  <span class="icon">
                    <i class="fas fa-mobile-alt"></i>
                  </span>
                  <span>+502 47069770</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactPage
