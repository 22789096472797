import React from "react"

const Footer = () => (
  <footer className="footer fp-auto-height has-background-primary has-text-white is-clearfix">
    <div className="container">
      <div className="columns">
        <div className="column">
          <p>
            <a
              className="has-text-weight-bold has-text-white is-uppercase is-size-5"
              href="/"
            >
              Aisolutions
            </a>
          </p>
          <p></p>
          <span>Aisolutions | AIBOT {new Date().getFullYear()}</span>
        </div>
        <div className="column">
          <p>
            <a
              href="/solicitud-de-demo/"
              className="has-text-weight-bold has-text-white is-uppercase is-size-5"
            >
              Solicitud de demo
            </a>
          </p>
        </div>
        <div className="column">
          <p>
            <a
              href="/contacto/"
              className="has-text-weight-bold has-text-white is-uppercase is-size-5"
            >
              Contacto
            </a>
          </p>
        </div>
        <div className="column is-offset-1">
          <p
            className="has-text-weight-bold has-text-white is-uppercase is-size-5"
            href="#"
          >
            Suscribirme
          </p>
          <p>
            Suscribete para recibir noticias y nuevos productos desarrollados
            por AISOLUTIONS
          </p>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input className="input" type="email" placeholder="Email" />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fas fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <button className="button is-primary is-inverted is-outlined is-uppercase">
                Enviar
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
