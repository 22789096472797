/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Navbar from "./navbar"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <script>{`
          window.fbMessengerPlugins = window.fbMessengerPlugins || {
            init: function () {
              FB.init({
                appId            : '1678638095724206',
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v3.0'
              });
            }, callable: []
          };
          window.fbAsyncInit = window.fbAsyncInit || function () {
            window.fbMessengerPlugins.callable.forEach(function (item) { item(); });
            window.fbMessengerPlugins.init();
          };
          setTimeout(function () {
            (function (d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) { return; }
              js = d.createElement(s);
              js.id = id;
              js.src = "//connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
          }, 0);
          `}</script>
        </Helmet>
        <Navbar />
        <main className="is-clearfix">{children}</main>
        <Footer />
        <div className="fb-customerchat" page_id="1294786133992523"></div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
