import React from "react"
import { Link } from "gatsby"

import logo from "../images/aisolutions-logo.png"

export default class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      pathname: "",
    }
    this.openMenu = this.openMenu.bind(this)
  }

  componentDidMount() {
    this.setState((state, props) => {
      let this_pathname = window.location.pathname
      this_pathname = this_pathname.replace("/", "")
      this_pathname = this_pathname.replace("/", "")
      return { pathname: this_pathname }
    })
  }

  openMenu() {
    this.setState((state, props) => {
      return { isOpen: !state.isOpen }
    })
  }

  render() {
    return (
      <nav className="navbar is-fixed-top is-clearfix">
        <div className="container">
          <div className="navbar-brand is-vcentered">
            <Link to="/" className="navbar-item">
              <img src={logo} alt="Aisolutions - Tecnología para todo" />
            </Link>
            <a
              role="button"
              className={
                this.state.isOpen ? "navbar-burger is-active" : "navbar-burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarPrimary"
              onClick={this.openMenu}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div
            id="navbarPrimary"
            className={
              this.state.isOpen ? "navbar-menu is-active" : "navbar-menu"
            }
          >
            <div className="navbar-end">
              <a
                href="/"
                className={
                  this.state.pathname == ""
                    ? "navbar-item is-uppercase has-text-white is-active"
                    : "navbar-item is-uppercase has-text-white"
                }
              >
                Inicio
              </a>
              <a
                href="/solicitud-de-demo/"
                className={
                  this.state.pathname == "solicitud-de-demo"
                    ? "navbar-item is-uppercase has-text-white is-active"
                    : "navbar-item is-uppercase has-text-white"
                }
              >
                Solicitud de demo
              </a>
              <a
                href="/contacto/"
                className={
                  this.state.pathname == "contacto"
                    ? "navbar-item is-uppercase has-text-white is-active"
                    : "navbar-item is-uppercase has-text-white"
                }
              >
                Contacto
              </a>
              <div className="navbar-item">
                <div className="field is-grouped">
                  <p className="control">
                    <a
                      className="button is-medium-touch is-small-desktop is-white"
                      target="_blank"
                      href="https://www.linkedin.com/company/hello-ai-solutions/"
                    >
                      <span className="icon">
                        <i className="fab fa-linkedin has-text-primary"></i>
                      </span>
                    </a>
                  </p>
                  <p className="control">
                    <a
                      className="button is-medium-touch is-small-desktop is-white"
                      target="_blank"
                      href="https://www.facebook.com/helloaisolutions/"
                    >
                      <span className="icon">
                        <i className="fab fa-facebook-f has-text-primary"></i>
                      </span>
                    </a>
                  </p>
                  <p className="control">
                    <a
                      className="button is-medium-touch is-small-desktop is-white"
                      target="_blank"
                      href="https://www.instagram.com/aisolutions_/"
                    >
                      <span className="icon">
                        <i className="fab fa-instagram has-text-primary"></i>
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
